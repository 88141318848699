import React from "react";
import PropTypes from "prop-types";
import AutoCompleteField from "./AutoCompleteField";
import ErrorUtil from "../../../../util/ErrorUtil";

class AutoRequestField extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        hideLabel: PropTypes.bool,
        request: PropTypes.func.isRequired,
        className: PropTypes.string,
        defaultMatchMode: PropTypes.string,
        defaultValue: PropTypes.object,
        autoSelect: PropTypes.bool,
        delay: PropTypes.number,
        edit: PropTypes.bool,
        suffix: PropTypes.string,
        onSuffixClick: PropTypes.func,
        error: PropTypes.bool,
        itemTemplate: PropTypes.func,
        onSelect: PropTypes.func,
        additionalFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        dropdown: PropTypes.bool,
        required: PropTypes.bool,
        clearable: PropTypes.bool,
        hideAddon: PropTypes.bool,
        appendTo: PropTypes.any
    };

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue,
            suggestions: [],
            matchMode: this.props.defaultMatchMode || "like"
        };
    }

    async retrieveSuggestions(value) {
        try {
            const response = await this.props.request(
                {
                    name: this.props.field,
                    value: value.trim(),
                    matchMode: this.state.matchMode
                },
                this.props.additionalFilter && !Array.isArray(this.props.additionalFilter)
                    ? [this.props.additionalFilter]
                    : this.props.additionalFilter
            );
            this.setState({suggestions: response || []});
        } catch (err) {
            ErrorUtil.handleError("Retrieving suggestions failed", err);
        }
    }

    reset = (value = this.props.defaultValue) => {
        console.info(this.props.name, "is resetting to:", value !== "" ? value : "empty string");
        this.setState({
            value: value,
            suggestions: []
        });
    };

    onMatchModeChange = (matchMode) => {
        this.setState({matchMode: matchMode});
    };

    onComplete = (completeEvent) => {
        this.retrieveSuggestions(completeEvent.query);
    };

    onSelect = (selectEvent) => {
        selectEvent.name = this.props.name;
        this.handleEvent(selectEvent, this.props.onSelect);
    };

    handleEvent = (event, targetFunction) => {
        this.setState({
            value: typeof event.value !== "undefined" ? event.value : event.target.value
        });
        if (targetFunction) {
            targetFunction(event);
        }
    };

    render() {
        return (
            <AutoCompleteField
                name={this.props.name}
                className={this.props.className}
                field={this.props.field}
                label={this.props.label}
                hideLabel={this.props.hideLabel}
                value={
                    this.state.value && typeof this.state.value === "object"
                        ? this.state.value[this.props.field]
                        : this.state.value
                }
                autoSelect={this.props.autoSelect}
                delay={this.props.delay}
                edit={this.props.edit}
                suffix={this.props.suffix}
                onSuffixClick={this.props.onSuffixClick}
                error={this.props.error}
                suggestions={this.state.suggestions}
                itemTemplate={this.props.itemTemplate}
                onComplete={this.onComplete}
                onSelect={this.onSelect}
                dropdown={this.props.dropdown}
                defaultMatchMode={this.state.matchMode}
                onMatchModeChange={this.onMatchModeChange}
                clearable={this.props.clearable}
                hideAddon={this.props.hideAddon}
                appendTo={this.props.appendTo}
                required={this.props.required}
            />
        );
    }
}

export default AutoRequestField;
