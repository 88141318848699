import {Store} from "../store/Store";

class Role {
    permissions = {};

    constructor(id) {
        this.id = id;
    }

    addPermission(feature, permission) {
        if (permission) {
            if (!this.permissions[permission]) {
                this.permissions[permission] = [];
            }
            this.permissions[permission].push(feature.id);
        } else {
            for (const key in Permissions) {
                this.addPermission(feature, key);
            }
        }
        //Edit and delete rights implicitly include view rights.
        if (permission !== Permissions.view) {
            this.addPermission(feature, Permissions.view);
        }
        return this;
    }
}

class Feature {
    //Administration Features
    static counter = 1;

    constructor(id) {
        this.id = id;
    }
}

function createRoleWithAll(id, ...exceptions) {
    const dev = new Role(id);
    for (const key in Features) {
        if (!exceptions || !exceptions.includes(Features[key])) {
            dev.addPermission(Features[key]);
        }
    }
    return dev;
}

export const Permissions = {
    view: "view",
    edit: "edit",
    delete: "delete"
};

export const Features = {
    own_equipment: new Feature("own_equipment"),
    truck_buddy: new Feature("truck_buddy"),
    settings: new Feature("settings"),
    dashboard: new Feature("dashboard"),
    dashboard_FM: new Feature("dashboard_FM"),
    dashboard_TM: new Feature("dashboard_TM"),
    dashboard_WS: new Feature("dashboard_WS"),
    development: new Feature("development"),
    trailer: new Feature("trailer"),
    trailer_create: new Feature("trailer_create"),
    trailer_multiEdit: new Feature("trailer_multiEdit"),
    trailer_multiDamage: new Feature("trailer_multiDamage"),
    trailer_admin: new Feature("trailer_admin"),
    trailer_tire: new Feature("trailer_tire"),
    trailer_report: new Feature("trailer_report"),
    trailer_internal: new Feature("trailer_internal"),
    ticket: new Feature("ticket"),
    ticket_changeLocation: new Feature("ticket_changeLocation"),
    ticket_FM: new Feature("ticket_FM"),
    ticket_TM: new Feature("ticket_TM"),
    order: new Feature("order"),
    order_FM: new Feature("order_FM"),
    order_TM: new Feature("order_TM"),
    order_WS: new Feature("order_WS"),
    partner_ticket: new Feature("partner_ticket"),
    partner_order: new Feature("partner_order"),
    partner_TM: new Feature("partner_TM"),
    partner_FM: new Feature("partner_FM"),
    partner_WS: new Feature("partner_WS"),
    partner_TB: new Feature("partner_TB"),
    inspection: new Feature("inspection"),
    inspection_FM: new Feature("inspection_FM"),
    damage: new Feature("damage"),
    debtor: new Feature("debtor"),
    debtor_FM: new Feature("debtor_FM"),
    address: new Feature("address"),
    address_businessHours: new Feature("address_businessHours"),
    address_businessHoursNote: new Feature("address_businessHoursNote"),
    address_agreement: new Feature("address_agreement"),
    address_note: new Feature("address_note"),
    address_credit_rate: new Feature("address_credit_rate"),
    report: new Feature("report"),
    file: new Feature("file"),
    file_FM: new Feature("file_FM"),
    file_trailer_category: new Feature("file_trailer_category"),
    file_trailer_customprops: new Feature("file_trailer_customprops"),
    file_trailer_remove: new Feature("file_trailer_remove"),
    file_ticket_category: new Feature("file_ticket_category"),
    file_ticket_customprops: new Feature("file_ticket_customprops"),
    file_ticket_remove: new Feature("file_ticket_remove"),
    // file_order_category: new Feature("file_order_category"),
    file_order_customprops: new Feature("file_order_customprops"),
    file_order_remove: new Feature("file_order_remove"),
    file_debtor_category: new Feature("file_debtor_category"),
    file_debtor_customprops: new Feature("file_debtor_customprops"),
    // file_debtor_remove: new Feature("file_debtor_remove"),
    file_send: new Feature("file_send"),
    management_user: new Feature("management_user"),
    management_user_admin: new Feature("management_user_admin"),
    management_news: new Feature("management_news"),
    management_tasks: new Feature("management_tasks"),
    management_translation: new Feature("management_translation"),
    management_shipment: new Feature("management_shipment"),
    management_inspection: new Feature("management_inspection"),
    management_settings: new Feature("management_settings"),
    basedata_trailertype: new Feature("basedata_trailertype"),
    basedata_trailerstate: new Feature("basedata_trailerstate"),
    basedata_damagestate: new Feature("basedata_damagestate"),
    basedata_ticketstate: new Feature("basedata_ticketstate"),
    history_json: new Feature("history_json"),
    profile: new Feature("profile"),
    profile_intern: new Feature("profile_intern"),
    monitoring_overview: new Feature("monitoring_overview"),
    watch: new Feature("watch")
};

export const Roles = {
    developer: createRoleWithAll("developer", Features.order_WS),
    admin: createRoleWithAll("admin", Features.development, Features.order_WS),
    system: new Role("system"),
    fmAdmin: new Role("fmAdmin")
        .addPermission(Features.own_equipment)
        .addPermission(Features.truck_buddy)
        .addPermission(Features.settings)
        .addPermission(Features.dashboard)
        .addPermission(Features.dashboard_FM)
        .addPermission(Features.ticket)
        .addPermission(Features.ticket_changeLocation)
        .addPermission(Features.ticket_FM)
        .addPermission(Features.ticket_TM)
        .addPermission(Features.trailer)
        .addPermission(Features.trailer_create)
        .addPermission(Features.trailer_multiEdit)
        .addPermission(Features.trailer_multiDamage)
        .addPermission(Features.trailer_admin)
        .addPermission(Features.trailer_tire)
        .addPermission(Features.trailer_report)
        .addPermission(Features.trailer_internal)
        .addPermission(Features.order)
        .addPermission(Features.order_FM)
        .addPermission(Features.order_TM)
        .addPermission(Features.inspection)
        .addPermission(Features.inspection_FM)
        .addPermission(Features.damage)
        .addPermission(Features.debtor)
        .addPermission(Features.debtor_FM)
        .addPermission(Features.address)
        .addPermission(Features.address_businessHours)
        .addPermission(Features.address_businessHoursNote)
        .addPermission(Features.address_agreement)
        .addPermission(Features.address_note)
        .addPermission(Features.address_credit_rate)
        .addPermission(Features.partner_ticket, Permissions.edit)
        .addPermission(Features.partner_order)
        .addPermission(Features.partner_FM)
        .addPermission(Features.partner_TM)
        .addPermission(Features.file)
        .addPermission(Features.file_FM)
        .addPermission(Features.file_trailer_category)
        .addPermission(Features.file_trailer_customprops)
        .addPermission(Features.file_trailer_remove)
        .addPermission(Features.file_ticket_category)
        .addPermission(Features.file_ticket_customprops)
        .addPermission(Features.file_ticket_remove)
        .addPermission(Features.file_debtor_category)
        .addPermission(Features.file_debtor_customprops)
        .addPermission(Features.file_order_customprops)
        .addPermission(Features.file_send)
        .addPermission(Features.management_user)
        .addPermission(Features.management_user_admin, Permissions.edit)
        .addPermission(Features.management_news)
        .addPermission(Features.management_tasks)
        .addPermission(Features.management_translation)
        .addPermission(Features.management_shipment)
        .addPermission(Features.management_settings)
        .addPermission(Features.basedata_trailertype)
        .addPermission(Features.basedata_trailerstate)
        .addPermission(Features.basedata_damagestate)
        .addPermission(Features.basedata_ticketstate)
        .addPermission(Features.history_json)
        .addPermission(Features.profile)
        .addPermission(Features.profile_intern)
        .addPermission(Features.report)
        .addPermission(Features.monitoring_overview)
        .addPermission(Features.watch),
    fmUser: new Role("fmUser")
        .addPermission(Features.own_equipment)
        .addPermission(Features.truck_buddy)
        .addPermission(Features.dashboard)
        .addPermission(Features.dashboard_FM)
        .addPermission(Features.ticket)
        .addPermission(Features.ticket_changeLocation)
        .addPermission(Features.ticket_FM)
        .addPermission(Features.ticket_TM)
        .addPermission(Features.trailer)
        .addPermission(Features.trailer_create)
        .addPermission(Features.trailer_multiEdit)
        .addPermission(Features.trailer_multiDamage)
        .addPermission(Features.trailer_tire)
        .addPermission(Features.trailer_report)
        .addPermission(Features.trailer_internal)
        .addPermission(Features.order)
        .addPermission(Features.order_FM)
        .addPermission(Features.order_TM)
        .addPermission(Features.partner_ticket, Permissions.edit)
        .addPermission(Features.partner_order)
        .addPermission(Features.partner_FM)
        .addPermission(Features.partner_TM)
        .addPermission(Features.inspection)
        .addPermission(Features.inspection_FM)
        .addPermission(Features.damage)
        .addPermission(Features.debtor)
        .addPermission(Features.debtor_FM)
        .addPermission(Features.address, Permissions.edit)
        .addPermission(Features.address_businessHours, Permissions.edit)
        .addPermission(Features.address_businessHoursNote, Permissions.edit)
        .addPermission(Features.address_agreement, Permissions.edit)
        .addPermission(Features.address_note, Permissions.edit)
        .addPermission(Features.address_credit_rate)
        .addPermission(Features.file)
        .addPermission(Features.file_FM)
        .addPermission(Features.file_send)
        .addPermission(Features.file_trailer_category)
        .addPermission(Features.file_trailer_customprops)
        .addPermission(Features.file_trailer_remove)
        .addPermission(Features.file_ticket_category)
        .addPermission(Features.file_ticket_customprops)
        .addPermission(Features.file_ticket_remove)
        .addPermission(Features.file_order_customprops)
        .addPermission(Features.file_debtor_category)
        .addPermission(Features.file_debtor_customprops)
        .addPermission(Features.management_user)
        .addPermission(Features.profile)
        .addPermission(Features.profile_intern, Permissions.view)
        .addPermission(Features.report, Permissions.view)
        .addPermission(Features.monitoring_overview)
        .addPermission(Features.watch),
    tmUser: new Role("tmUser")
        .addPermission(Features.own_equipment)
        .addPermission(Features.truck_buddy)
        .addPermission(Features.dashboard)
        .addPermission(Features.dashboard_TM, Permissions.view)
        .addPermission(Features.ticket)
        .addPermission(Features.ticket_TM)
        .addPermission(Features.trailer, Permissions.view)
        .addPermission(Features.trailer_tire, Permissions.view)
        .addPermission(Features.trailer_internal)
        .addPermission(Features.order)
        .addPermission(Features.order_TM)
        .addPermission(Features.partner_ticket, Permissions.edit)
        .addPermission(Features.partner_TM, Permissions.view)
        .addPermission(Features.inspection)
        .addPermission(Features.damage)
        .addPermission(Features.address, Permissions.view)
        .addPermission(Features.address_businessHours, Permissions.view)
        .addPermission(Features.address_businessHoursNote, Permissions.view)
        .addPermission(Features.address_agreement, Permissions.view)
        .addPermission(Features.address_note, Permissions.view)
        .addPermission(Features.address_credit_rate)
        .addPermission(Features.file)
        .addPermission(Features.file_send)
        .addPermission(Features.file_ticket_category)
        .addPermission(Features.file_ticket_customprops)
        .addPermission(Features.file_ticket_remove)
        .addPermission(Features.file_order_customprops)
        .addPermission(Features.profile, Permissions.view)
        .addPermission(Features.profile_intern, Permissions.view)
        .addPermission(Features.watch),
    wsAdmin: new Role("wsAdmin")
        .addPermission(Features.own_equipment)
        .addPermission(Features.truck_buddy)
        .addPermission(Features.settings)
        .addPermission(Features.dashboard)
        .addPermission(Features.dashboard_WS, Permissions.view)
        .addPermission(Features.management_user)
        .addPermission(Features.trailer, Permissions.view)
        .addPermission(Features.ticket, Permissions.edit)
        .addPermission(Features.order)
        .addPermission(Features.order_WS)
        .addPermission(Features.partner_order)
        .addPermission(Features.partner_WS)
        .addPermission(Features.address, Permissions.view)
        .addPermission(Features.address_businessHours, Permissions.view)
        .addPermission(Features.file)
        .addPermission(Features.file_ticket_category)
        .addPermission(Features.file_ticket_customprops)
        .addPermission(Features.file_ticket_remove)
        .addPermission(Features.file_order_customprops)
        .addPermission(Features.profile, Permissions.view),
    wsUser: new Role("wsUser")
        .addPermission(Features.own_equipment)
        .addPermission(Features.truck_buddy)
        .addPermission(Features.dashboard)
        .addPermission(Features.dashboard_WS, Permissions.view)
        .addPermission(Features.trailer, Permissions.view)
        .addPermission(Features.ticket, Permissions.edit)
        .addPermission(Features.order)
        .addPermission(Features.order_WS)
        .addPermission(Features.partner_order)
        .addPermission(Features.partner_WS)
        .addPermission(Features.file)
        .addPermission(Features.file_ticket_category)
        .addPermission(Features.file_ticket_customprops)
        .addPermission(Features.file_ticket_remove)
        .addPermission(Features.file_order_customprops)
        .addPermission(Features.profile, Permissions.view),
    tbPartner: new Role("tbPartner")
        .addPermission(Features.own_equipment)
        .addPermission(Features.truck_buddy)
        .addPermission(Features.dashboard)
        .addPermission(Features.dashboard_WS, Permissions.view)
        .addPermission(Features.trailer, Permissions.view)
        .addPermission(Features.ticket, Permissions.edit)
        .addPermission(Features.order)
        .addPermission(Features.order_WS)
        .addPermission(Features.partner_order)
        .addPermission(Features.partner_WS)
        .addPermission(Features.file)
        .addPermission(Features.file_ticket_category)
        .addPermission(Features.file_ticket_customprops)
        .addPermission(Features.file_ticket_remove)
        .addPermission(Features.file_order_customprops)
        .addPermission(Features.profile, Permissions.view)
        .addPermission(Features.truck_buddy)
        .addPermission(Features.partner_TB, Permissions.edit)
        .addPermission(Features.partner_ticket, Permissions.edit)
};

const findRoleById = (roleId) => {
    for (const roleKey in Roles) {
        if (Roles[roleKey].id === roleId) {
            return Roles[roleKey];
        }
    }
    return roleId ? new Role(roleId) : null;
};

export const isJWTDev = () => {
    if (
        !Store.getState().session ||
        !Store.getState().session.jwt ||
        !Store.getState().session.jwt.indexOf(".")
    ) {
        return false;
    }
    const loginUserData = JSON.parse(atob(Store.getState().session.jwt.split(".")[1]));
    return loginUserData.role === Roles.developer.id;
};

export const hasPermission = (permission, ...features) => {
    return roleIdHasPermission(Store.getState().session.role, permission, ...features);
};

export const roleIdHasPermission = (roleId, permission, ...features) => {
    const role = findRoleById(roleId);
    const hasPermission = roleHasPermission(role, permission, ...features);
    return hasPermission;
};

export const roleHasPermission = (role, permission, ...features) => {
    if (role && permission in role.permissions && features) {
        for (let i = 0; i < features.length; ++i) {
            if (
                role.permissions[permission] &&
                features[i] &&
                role.permissions[permission].includes(features[i].id)
            ) {
                return true;
            }
        }
    }
    return false;
};
