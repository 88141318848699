import {simpleRequest} from "../util/RequestUtil";
import UriList from "../service/EndpointsList";

class WatchRequest {
    static async isSubscribed(uuid) {
        const response = await simpleRequest(UriList.WATCH_IS_SUBSCRIBED + "/" + uuid);
        return response;
    }

    static async isSubscribedForUser(uuid, userUUID) {
        const response = await simpleRequest(
            UriList.WATCH_IS_SUBSCRIBED + "/" + uuid + "/user/" + userUUID
        );
        return response;
    }

    static async subscribe(watch) {
        const response = await simpleRequest(UriList.WATCH_SUBSCRIBE, {
            method: "POST",
            formdata: true,
            body: JSON.stringify(watch)
        });
        return response;
    }

    static async unsubscribe(uuid) {
        const response = await simpleRequest(UriList.WATCH_UNSUBSCRIBE + "/" + uuid, {
            method: "DELETE"
        });
        return response;
    }

    static async unsubscribeForUser(uuid, userUUID) {
        const response = await simpleRequest(
            UriList.WATCH_UNSUBSCRIBE + "/" + uuid + "/user/" + userUUID,
            {
                method: "DELETE"
            }
        );
        return response;
    }

    static async isNew() {
        const response = await simpleRequest(UriList.WATCH_IS_NEW);
        return response;
    }

    static async markAsRead(uuid) {
        const response = await simpleRequest(UriList.WATCH_MARK_AS_READ + "/" + uuid, {
            method: "PUT"
        });
        return response;
    }

    static async deleteObsolete(uuid) {
        const response = await simpleRequest(UriList.WATCH_DELETE_OBSOLETE + "/" + uuid, {
            method: "DELETE"
        });
        return response;
    }
}

export default WatchRequest;
