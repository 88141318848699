// @ts-check
class InspectionDefinition {
    static defaultDialogState = {
        element: {
            uuid: null,
            vehicleUUID: null,
            legalInspectionUUID: null,
            source: "",
            stateCondition: 0,
            creDat: "",
            creUser: null,
            vin: "",
            licensePlate: "",
            iluCode: "",
            scheduledDate: "",
            nextDate: "",
            servicepartnerUUID: null,
            servicepartnerName1: "",
            servicepartnerReferenceID: null,
            toType: 3,
            fromType: 3,
            loaded: -1,
            dispoUserUUID: null,
            dispoUserFullname: "",
            dispoUserLastname: "",
            dispoUserFirstname: "",
            note: ""
        }
    };

    static defaultFilter = null;

    static defaultState = {
        entries: [],
        selectionUUIDs: [],
        dialog: InspectionDefinition.defaultDialogState,
        filter: InspectionDefinition.defaultFilter,
        sort: {sortField: "nextDate", sortOrder: -1},
        rows: 50,
        first: 0,
        totalRecords: 0
    };
}

export default InspectionDefinition;
