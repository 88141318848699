import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class MsgHubAction {
    static CATEGORY = "MsgHub";

    // #region News Overview
    static LOADING = "MSG_HUB_LOADING";
    static DATATABLE = "MSG_HUB_LOADING";
    static CLEAR = "MSG_HUB_CLEAR";
    static UPDATE = "MSG_HUB_CHANGE";
    // #endregion

    // #region Actions
    static create = DefaultStoreUtil.defaultCreateAction(MsgHubAction.CATEGORY);
}

export default MsgHubAction;
